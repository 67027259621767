import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { PatientData, stats } from "./mockdata/PatientData";
import StatCard from "../UI/StatCard";
import { RiCalendar2Fill } from "react-icons/ri";
import FinanceTable from "../tables/financeTable";
import DiagnosisTable from "../tables/DiagnosisTable";
import PatientsTable from "../tables/PatientsTable";
import { get } from "../../utility/fetch";

function ManageRequest() {

  const [patientData, setPatientData] = useState();

  const getPatients = async() =>{
    let res = await get("/patient/list")
    console.log(res)
    setPatientData(res.resultList);
  }


  useEffect(() => {
    getPatients()
  }, []);
 

  return (
    <div className="w-100 m-t-80">
      <h3>Diagnosis & Treatment</h3>
      
      <div className="flex w-100 space-between">
        
        <div className="flex flex-v-center  w-50 m-t-20">
         
          <input type="text" className="" />
          <div className="dropdown-input w-25 ">
          </div>
        </div>
      </div>

      <div className="">
        <PatientsTable data={patientData} />
      </div>
    </div>
  );
}

export default ManageRequest;
