import React, { useState } from 'react';
import { useEffect } from "react";
import MedicationModal from "../layouts/MedicationModal";
import { useSearchParams } from "react-router-dom";
import { get } from "../../utility/fetch";
import UpdateModal from '../layouts/UpdateModal';
import viewIcon from '../../assets/images/viewIcon.png';
import Pagination from 'rc-pagination';
import { GridLoader } from 'react-spinners';

function ThresholdItemTable({ data }) {

  const [searchParams] = useSearchParams();
  const [inventory, setInventory] = useState([]);
  const [thresholdInventory, setThresholdInventory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalId, setModalId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPaginationCount, setTotalPaginationCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const getThresholdInventory = async (page = 1) => {
    setLoading(true)
    let res = await get(`/pharmacyinventory/threshold-list/${page}/10`)
    console.log(res)
    setThresholdInventory(res?.resultList);
    setTotalPaginationCount(res?.totalPages * 10);
    setLoading(false)
  }

  useEffect(() => {
    getThresholdInventory()
  }, [])

  const handleButtonClick = (row, item) => {
    // Handle the button click event here
    console.log('Button clicked for row:', row);
    setSelectedRow(row);
    setModalId(item?.id)
    setIsModalOpen(true);
  };

  const handleChangePagination = (page) => {
    setPageNumber(page)
    getThresholdInventory(page);
  }


  return (
    <div className="w-100 ">
      <div className="w-100 none-flex-item">
        <div style={{ backgroundColor: "#EBFFE6", color: "#17621A" }} className="p-10"><h3>Inventory Management</h3></div>
        <table className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">
              <th>Item</th>
              <th>Inventory #ID</th>
              {/* <th>Nurse Note</th> */}
              <th>Manufacturer</th>
              <th>Stored Quantity</th>
              <th>Available Quantity</th>
              <th>Supplier</th>
              <th>Last Restock</th>
              <th>View</th>
            </tr>
          </thead>

         {loading ? <div style={{paddingLeft:"500px", paddingTop:"200px"}} className='flex flex-h-center w-100 flex-v-center'><GridLoader /></div> : <tbody className="white-bg view-det-pane">
            {Array?.isArray(thresholdInventory) && thresholdInventory?.map((row, rowIndex) => (
              <React.Fragment key={row?.id}>
                <tr style={{ backgroundColor: "#FFEAEB" }}>
                  <td>
                    {row?.productName}
                  </td>
                  <td>{row?.mfgBatchNumber}</td>
                  <td>{row?.manufacturer}</td>
                  <td>{row?.storedQuantity}</td>
                  <td>{row?.availableQuantity}</td>
                  <td>{row?.supplier}</td>
                  <td>{row?.dateSupplied}</td>
                  <td > <img onClick={() => handleButtonClick(row)} className='pointer' src={viewIcon} alt="viewIcon" /></td>
                </tr>
              </React.Fragment>
            ))}

          </tbody>}
        </table>
      </div>
      <div className="flex flex-h-end m-t-20">
        <Pagination onChange={handleChangePagination} current={pageNumber} total={totalPaginationCount} />
      </div>
      {isModalOpen && <MedicationModal data={selectedRow} closeModal={() => setIsModalOpen(false)} />}
      {/* <MedicationModal /> */}
    </div>
  );
}

export default ThresholdItemTable;
