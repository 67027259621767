import React, { useState } from 'react';
import { useEffect } from "react";
import MedicationModal from "../layouts/MedicationModal";
import { useSearchParams } from "react-router-dom";
import { get } from "../../utility/fetch";
import UpdateModal from '../layouts/UpdateModal';
import { GridLoader } from 'react-spinners';

function DiagnosisTable({ data }) {

  const [searchParams] = useSearchParams();
  const [diagnosis, setDiagnosis] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalId, setModalId] = useState(0);
  const [loading, setLoading] = useState(false);

  const getPatientDiagnosis = async (id) => {
    setLoading(true)
    let res = await get(`/patient/${id}`)
    console.log(res)
    setDiagnosis(res);
    setLoading(false)
  }

  useEffect(() => {
    console.log(data)
    getPatientDiagnosis(searchParams.get('id'));
  }, [])

  const handleButtonClick = (row, item) => {
    // Handle the button click event here
    console.log('Button clicked for row:', row);
    setSelectedRow(row);
    setModalId(item?.id)
    setIsModalOpen(true);
  };




  return (
    <div className="w-100 ">
      <div className="w-100 none-flex-item m-t-20">
        <table className="bordered-table">
          <thead className="border-top-none">
            <tr className="border-top-none">
              <th>Date</th>
              <th>prescribing Doctor</th>
              {/* <th>Nurse Note</th> */}
              <th>Diagnosis</th>
              <th>Medication/Prescription</th>
              <th>Qty</th>
              <th>Freq/Day</th>
              <th>Duration(Days)</th>
              <th>Total Dispense</th>
              <th>Pharmacist's Note</th>
            </tr>
          </thead>

         {loading ? <div style={{paddingLeft:"500px", paddingTop:"200px"}} className='flex flex-h-center w-100 flex-v-center'><GridLoader /></div> : <tbody className="white-bg view-det-pane">
            {diagnosis?.treatments?.map((row, rowIndex) => (
              <React.Fragment key={row?.id}>
                <tr>
                  <td rowSpan={row?.prescriptions?.length + 1}>
                    {new Date(row?.dateOfVisit).toLocaleDateString()}
                  </td>
                  <td style={{whiteSpace:"nowrap"}} width={"200px"} rowSpan={row?.prescriptions?.length + 1}>{`${diagnosis?.doctor?.firstName} ${diagnosis?.doctor?.lastName}`}</td>
                  <td rowSpan={row?.prescriptions?.length + 1}>{row?.diagnosis}</td>
                </tr>
                {row?.prescriptions?.map((item, index) => (
                  <tr key={index}>
                    <td >{item?.pharmacyInventory?.productName || "NIL"}</td>
                    <td>{item.quantity}</td>
                    <td>{item.frequency}</td>
                    <td>{item.duration}</td>
                    <td>{item.totalToDispense}</td>
                    <td>{item?.isDispensed ? item.pharmacistNote : (
                      <button className='btn' onClick={() => handleButtonClick(row, item)}>Action</button>
                    )}</td>
                  </tr>
                ))}

                {rowIndex < diagnosis.treatments.length - 1 && (
                  <tr>
                    <td colSpan={9} style={{ height: '20px', backgroundColor: 'lightgrey' }}></td>
                  </tr>
                )}
              </React.Fragment>
            ))}

          </tbody>}
        </table>
      </div>
      {isModalOpen && <UpdateModal data={selectedRow} getPatientDiagnosis={getPatientDiagnosis} modalId={modalId} closeModal={() => setIsModalOpen(false)} />}
      {/* <MedicationModal /> */}
    </div>
  );
}

export default DiagnosisTable;
