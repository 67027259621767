/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

//import { RiBarChartFill, RiHeartPulseLine, RiHotelBedFill, RiHealthBookLine } from 'react-icons/ri';
import finance from '../assets/images/finance.svg'
import insurance from '../assets/images/Insurance.svg'
import referral from '../assets/images/referral.svg'
import dashboard from '../assets/images/dshb.svg'
//import logout from '../assets/images/log out.svg'
import facility from '../assets/images/faclty.svg'
import Customer from '../assets/images/cus eng.svg'
import patient from '../assets/images/pats.svg'
import settings from '../assets/images/settings.png';



// import { BiEnvelope } from 'react-icons/bi';
// import { HiOutlineUserAdd } from 'react-icons/hi';
// import { CgUserList } from 'react-icons/cg';

export default [
    // { title: 'Dashboard', href: '/dashboard', icon: <img src={dashboard}  className="icon"/> },
    // { title: 'User Management', href: '/user-management', icon: <img src={settings}  className="icon"/> },
    { title: 'Dashboard', href: '/dashboard', icon: <img src={patient} className="icon" /> },
    // { title: 'Files', href: '/files', icon: <img src={facility} className="icon" /> },
    // { title: 'Shared Files', href: '/shared-files', icon: <img src={referral} className="icon" /> },
    { title: 'Manage Request', href: '/manage-request', icon: <img src={finance} className="icon" /> },
    { title: 'Manage Inventory', href: '/manage-inventory', icon: <img src={finance} className="icon" /> },
    // { title: 'Recent', href: '/insurance', icon:<img src={insurance}  className="icon"/>  },
    // { title: 'Customer Engagement', href: '/customer-engagement', icon: <img src={Customer}  className="icon"/>},
    // { title: 'Deleted Files', href: '/deleted-files', icon: <img src={settings} className="icon" /> },
];
