import React from "react";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";


function PatientsTable({ data}) {

  console.log(data)

  let navigate = useNavigate()

  const continueUpdate =(id)=>{
    sessionStorage?.setItem("patientId", id);
    // sessionStorage?.setItem("status", "edit");
    navigate(`/treatment?id=${id}`)
  }


  return (
    <div className="w-100">
      <div className="w-100 none-flex-item">
        <div style={{backgroundColor:"#EBFFE6", color:"#17621A"}} className="p-10"><h3>Inventory Management</h3></div>
        <table className="bordered-table">
          <thead className="border-top-none">
            <tr style={{backgroundColor:"#CECECE", color:"#444444"}}  className="border-top-none">
              {/* <th>Patient #ID</th> */}
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Last Updated By</th>
              <th>Date Created</th>
            </tr>
          </thead>

          {data ? <div style={{paddingLeft:"500px", paddingTop:"200px"}} className='flex flex-h-center w-100 flex-v-center'><GridLoader /></div> : <tbody className="white-bg view-det-pane ">
            {data?.map((row) => (
              <tr className="hovers pointer" onClick={()=>continueUpdate(row?.id)} key={row.id}>
                <td>{row?.firstName}</td>
                <td>{row?.lastName}</td>
                <td>{row?.email}</td>
                <td>{`${row?.modifiedBy?.firstName} ${row?.modifiedBy?.lastName}`}</td>
                <td>{new Date(row?.createdAt)?.toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>}
        </table>
        
      </div>
    </div>
  );
}

export default PatientsTable;
